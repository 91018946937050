export default function ReloadButton({ withCache }) {
  return (
    <button
      className="btn btn-sm"
      onClick={() => {
        window.location.reload(!withCache)
      }} 
    >
      <i className="fa-solid fa-refresh" />
      Seite neu laden
    </button>
  )
}
